/**
 * @file
 * Styles for overall design
*/

@import "variables";

html, body {
  height: 100%;
}

body {
  background: $blue-dark;
  @media only screen and (max-width: 767px) {
    background: $red-dark;
  }
}

// Remove margin-top from body.admin-menu (used in conjunction with script.js)
html body.zero-admin-margin {
  margin-top: 0 !important;
}

// Turn off external link icon except for in content
span.ext {
  display: none !important;
}

#content span.ext {
  display: inline-block !important;
}

// Sticky Footer!
//@media only screen and (min-width: 768px) {
  //@include sticky-footer(144px, "#container", "#root-footer", "#footer");
//}

#footer {
  @include clearfix;
}

// 
// PAGE ABOVE 
//

#block-block-1 { // Header Contact Info
  text-align: center;
  background: $orange;
  width: 100%;
  padding: 8px 0;
  color: $red-dark;
  @include adjust-font-size-to($fs-m);
  position: absolute;
  left: 0;
  top: -93px;
  z-index: 2;
  @media only screen and (min-width: 768px) and (max-width: 1104px) {
    top: -45px;
    width: initial;
    left: inherit;
  }
  @media only screen and (min-width: 768px) {
    padding: 10px;
    float: right;
    color: $blue-dark;
    position: absolute;
  }
  @media only screen and (max-width: 767px) {
    top: -203px;
  }
  ul {
    list-style: none;
    //font-family: $secondary-typeface;
    @include secondary-typeface();
    margin: 0;
    padding: 0;
    display: inline;
    @media only screen and (max-width: 767px) {
      display: block;
    }
    li {
      display: inline;
      &:after {
        content: " | ";
      }
      &.last {
        &:after {
          content: none;
        }
      }
      a {
        color: $blue-dark;
        @media only screen and (max-width: 767px) {
          color: $red-dark;
        }
      }
    }
    &.first {
      li.last:after {
        content: " | ";
        @media only screen and (max-width: 767px) {
          content: none;
        }
      }
    }
  }
}

.not-front #block-block-1 {
  right: 10px;
  left: inherit;
  @media only screen and (min-width: 768px) {
    //right: 10px !important;
  }
  @media only screen and (max-width: 767px) {
    right: inherit;
    top: -202px;
  }
}

body.front #block-block-1 {
  @media only screen and (min-width: 768px) and (max-width: 1104px) {
    right: 90px;
    top: -147px;
  }
}

// 
// HEADER 
//

#header {
  background-color: $red-dark; // Old browsers
  @include background-image(linear-gradient(left,  $red-dark 0%,$red 50%,$red-dark 100%));
  @include box-shadow(0 0 10px rgba(black, 0.5));
  border-bottom: 3px solid $orange;
  border-top: 3px solid $orange;
  @media only screen and (max-width: 767px) {
    border: none;
  }
}

#block-bean-logo-and-tagline { // Logo, Site Name, Tagline
  background-color: transparent; // Old browsers
  @include background-image(none);
  padding: 10px 0;
  text-align: center;
  color: white;
  
  @media only screen and (max-width: 767px) {
    background-color: $red-dark; // Old browsers
    @include filter-gradient($red-dark, $red, horizontal); // IE6-9
    @include background-image(linear-gradient(left,  $red-dark 0%,$red 50%,$red-dark 100%));
  }

  a {
    color: white;
    &:hover { 
      text-decoration: none;
    }
  }
  h1 {
    width: 276px;
    height: 80px;
    //background: url(../images/logo.png) transparent no-repeat;
    margin: 0;
    //text-indent: -9999px;
    @include adjust-font-size-to(73px);
    line-height: 60px;
    text-align: left;
    
    @media only screen and (max-width: 767px) {
      width: 180px;
      height: inherit;
    }
    
    img {
      margin-bottom: 0;
      @media only screen and (max-width: 767px) {
        float: left;
      }
    }
    @media only screen and (max-width: 767px) {
      margin: 0 auto;
      
    }
  }
}


// Add 90 px padding to front page elements
.front {
  #block-bean-logo-and-tagline {
    padding-left: 90px;
    @media only screen and (max-width: 767px) {
      padding-left: 0;
    }
  }
  #header_right {
    padding-right: 90px;
    @media only screen and (max-width: 767px) {
      padding-right: 0;
    }
  }
  #navbar {
    padding-right: 80px;
    #nav-inner {
      position: relative;
    }
    @media only screen and (max-width: 1104px) {
      padding-right: 0;
      width: 100%;
    }
  }
  #page-top #block-block-1 {
    right: 90px;
  }
}

.not-front {
  #navbar {
    //padding-right: 10px;
  }
}

#header_right { // Header Right (Region)
  padding: 0 10px 7px 10px;
  border-top: none;
  border-bottom: none;
  background-color: transparent; // Old browsers
  @include background-image(none);
  height: initial;
  position: relative;
  height: 44px;

  @media only screen and (max-width: 767px) {
    padding: 0;
    border-top: 3px solid $orange;
    border-bottom: 1px solid #00213d;
    background-color: #00213d; // Old browsers
    @include filter-gradient(#023b72, #00213d, vertical); // IE6-9
    @include background-image(linear-gradient(top, #023b72 0%,#00213d 100%));
  }
}
  
#mobile-menu-link { //mobile menu button
  display: none;
  float: left;
  //text-indent: -999px;
  color: white;
  font-size: 0;
  background: $orange;
  padding: 2px 5px;
  position: absolute;
  text-transform: uppercase;
  font-family: $primary-typeface;
  left: 0;
  top: 0;
  height: 41px;
  
  &:before {
    content: "3";
    height: 15px;
    width: 30px;
    font-size: 28px;
    display: block;
    font-family: fontello;
    text-align: center;
    position: relative;
    top: 5px;
    line-height: 1em;
    //@include icons-sprite(mobile-menu);
  }
  &:hover {
    text-decoration: none;
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

#block-menu-block-2 { // Cart and Misc. Links Menu
  @include secondary-typeface();
  color: orange;
  font-size: $fs-m;
  //display: inline-block;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 767px) {
    padding: 8px 10px 2px;
  }
  
  ul {
    text-align: right;
    margin: 0;
    padding: 0;
    float: right;
    
    @media only screen and (max-width: 767px) {
      top: 7px;
      position: relative;
    }
    
    li { 
      padding: 0;
      margin: 0;
      position: relative;
      //float: left;
      &:after {
        content: " | ";
      }
      &.last:after, &.first:after {
        content: none;
      }
      &.first {
      	margin-right: 10px;
      	&:after {
      		content: none;	
      	}
      }
      &.last {
      	&:after {
      		content: none;			
      	}
      }
      	
      a {
        color: $orange;
        padding: 6px 0 5px;
        display: inline-block;
        @media only screen and (max-width: 767px) {
          padding: 2px 0;
        }
        &:hover {
          text-decoration: none;
        }
      }
      a.cart {
        //@include icons-sprite(cart);
        //@include get-sprite($icons, cart-s);
        font-size: 0;
        padding-left: 45px;
        //position: relative;
        &:before {
          font-family: fontello;
          content: "$";
          color: white;
          font-size: 43px;
          position: absolute;
          left: 6px;
          line-height: 1em;
          top: -9px;
          @media only screen and (max-width: 767px) {
            left: 12px;
            top: -7px;
            font-size: 32px;
          }
        }
      }
      
      span.items-in-cart {
        background: #4F8A10;
        color: $white;
        padding: 3px 5px 2px;
        position: absolute;
        left: 50%;
        margin-top: -7px;
        font-size: 14px;
        font-family: sans-serif;
        line-height: 1em;
        @include border-radius(3px);
        margin-left: 5px;
        @include box-shadow(-2px 2px 0px 1px rgba($black, 0.9)); 
      }
      span.items-in-cart.empty {
      	display: none;
      }
    }
  }
}

// Hide Cart Button During Checkout
body.page-checkout {
  #block-menu-block-2 ul li a.cart {
    display: none;
  }
  #block-menu-block-2 span.items-in-cart {
  	display: none;
  }
}

@media only screen and (max-width: 767px) { // Throws Deprecation Error
  #header_right #block-menu-block-2 ul li a.cart {
    //@include icons-sprite(cart-s);
  }
}

.front #header_right #block-menu-block-2 { // Cart and Misc. Links Menu
  @media only screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}

//
// Navigation
//

#block-search-form {
  .form-item {
    float: left;
    margin: 0;
    input {
      border: 1px solid #ccc;
    }  
  }
  .form-actions {
    input {
      background: $orange;
      color: $blue-dark;
      min-height: 27px;
      margin-right: 0;
    }
  }
}

.not-front #navbar #block-search-form {
  @media only screen and (max-width: 1104px) {
    top: 54px;
  }
}

#block-menu-block-1 { // Main Menu Block
  //font-family: $secondary-typeface;
  @include secondary-typeface();
  font-size: $fs-xl;    
  @media only screen and (max-width: 1104px) {
    background-color: $blue-dark; // Old browsers
    @include filter-gradient($blue-light, $blue-dark, vertical); // IE6-8
    $experimental-support-for-svg: true;
    @include background-image(linear-gradient(top, $blue-light 0%,$blue-dark 100%));
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 10px 0 0;
      padding: 0;
      &.last {
        margin: 0;
      }
      a {
        color: white;
        padding: 10px 10px 20px;
        display: block;
        @include transition(background .05s ease-in);
        &:hover {
          background: $orange;
          text-decoration: none;
          @include transition(background .05s ease-in);
        }
        @media only screen and (max-width: 1104px) {
          padding: 10px;
        }
      }
      &.active a {
        //background: $orange;
      }
    }
  }
}

//
// Main Content
//

#main {
  padding: 0 2% 10px;
  @media only screen and (max-width: 767px) {
    padding: 10px;
  }
  .pagewidth {
    background: white;
    color: $grey;
  }
}

// Filter These Results Button, Categories Button
#block-block-8, #block-block-9 {
  line-height: 1em;
  padding: 10px 10px 10px 37px;
  font-size: 1.25em;
  color: white;
  display: inline-block;
  cursor: pointer;
  margin: 0 0 10px;
  position: relative;
  
  @media only screen and (max-width: 767px) {
    display: none;
  }
  
  &:hover {
    padding-left: 42px;
    &:before {
      //left: 27px;
      @include transition(
        left 0.2s ease-in-out 0.2s
      );
    }
  }
  
  &.active {
    span {
      display: none;
    }
    &:after {
      display: none;
    }
    &:before {
      display: block;
    }
  }
  
  &:before {
    content: "<";
    font-family: fontello;
    position: absolute;
    left: 20px;
    top: 11px;
    @include transition(
      left 0.2s ease-in-out
    );
  }
  
  &.with-filters {
    padding-left: 42px;
    &:hover {
      padding-left: 49px;
    }
    &:before {
      content: "X";
    }
  }
  
  p { margin: 0; }

}

// Filter These Results Button
#block-block-8 {
  background: $red-dark;
  color: white;
  @include box-shadow(0 0 10px rgba(black, 0.2));
  @include transition(
    background 0.2s ease-in-out,
    padding 0.2s ease-in-out,
    left 0.2s ease-in-out 0.4s
  );
  &:hover, &.active {
    background: darken($red-dark, 10%);
  }
}

body.filters-on #block-block-8 {
  left: -230px;
  
  &.show {
    left: 0;
  }
}

// Categories Button
#block-block-9 {
  background: #eee;
  left: -240px;
  position: relative;
  color: $red-dark;
  @include box-shadow(0 0 10px rgba(black, 0.5));
  @include transition(
    left 0.6s ease-in-out 0.2s,
    background 0.2s ease-in-out,
    padding 0.2s ease-in-out
  );
  &.active {
    left: 0;
  }
  &:hover {
    background: white;  
  }
}

body.front #main .pagewidth { // Change some styles for front page
  @media only screen and (max-width: 767px) {
    background: $red;
    color: white;
    padding: 0;
  }
}

//
// Filter Region (For Geography & Theme - or other blocks)
//

#content-header {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  left: 0;
  @media only screen and (max-width: 767px) {
    @include box-shadow(0 0 10px rgba(black, 0.5));
  }
  
  @include transition(
    left 0.2s ease-in-out
  );
  
  // Action Links
  .action-links {
    position: absolute;
    right: 20px;
  }
  
}

body.page-node #content-header {
  //position: inherit;
}

#block-block-3, #block-block-4, #block-block-5, #block-block-6, #block-block-7 {
  display: none;
  cursor: pointer;
  font-family: fontello;
  line-height: 1em;
  font-size: 1.5em;
  padding: 9px;
  top: 0;  
  border-bottom: 1px solid darken($orange, 10%);
  @include border-radius(0 0 3px 0x);
  background: $orange;
  width: 100%;
  z-index: 4;
  @include transition(
    background 0.2s ease-in-out
  );
  
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
  
  &.closed-region {
    background: darken($orange, 10%);
  }
  
  &.active {
    width: 60%;
  }
  
  &.on-bottom {
    background: darken($orange, 5%);
    border-bottom: 1px solid darken($orange, 20%);
  }
  
  span {
    font-family: $primary-typeface;
    font-size: 14px;
    margin-left: 10px;
    position: relative;
    top: -2px;
    color: #444444;
  }
  
  p { margin: 0; }
  
  @media only screen and (max-width: 767px) {
    display: block; 
  }  
  
  &:hover {
    background: lighten($orange, 10%);
  }
  
}

// Close Filters
#block-block-5 {
  display: none;
  background: lighten($orange, 10%);
}

// Open Filters
#block-block-7 {
}


// Close Categories
#block-block-6 {
  display: none;
  background: lighten($orange, 10%);
}

// Open Categories
#block-block-4 {
}


#filter-region-bg {
  position: absolute;
 	background: $red-dark;
  bottom: -20px;
 	top: -20px;
 	left: 0;
 	z-index: 1;
 	width: 240px;
 	@include box-shadow(0 0 10px rgba(black,0.5));
 	@media only screen and (max-width: 767px) {
 	  display: none;
 	}
}

#filter-region {
  background: $red-dark;
  color: white;
  z-index: 2;

  &.active {
  	@include box-shadow(0 0 10px rgba(black, 0.5));
	}
  
  @media only screen and (max-width: 767px) {
    position: absolute;
    height: 10000px;
    width: 240px;
    left: -480px;
    margin-top: 86px;
    background: #eee;
    @include box-shadow(inset 0 -7px 7px rgba(black,0.2));
    &.on-top {
      left: -240px;
    }
    
        
    h2 {
      color: $blue-dark;
    }
    
  }
  
  a {
    color: $orange;
  }
  
  h2 {
    font-size: 2em;
    margin: 0 10px;
  }
  
  // Filters Button, Close Button
  #block-block-5, #block-block-6 {
    cursor: pointer;
    font-family: fontello;
    position: absolute;
    right: -32px;
    top: 0;
    border: 1px solid rgba(0,0,0,0.2);
    border-left: none;
    @include border-radius(0 0 3px 0);
    line-height: 1em;
    font-size: 1.3em;
    padding: 7px 7px 7px 5px;
    //background: #ddd;
    //background: #953138;
    background: #ccc;
    @include box-shadow(0 0 10px rgba(black,0.2));
    //border: 1px solid rgba(black,0.8);
    border-left: 2px solid rgba(black,0.1);
    border-top: 1px solid rgba(black,0.1);
    //color: $red-dark;
    color: $red-dark;
    
    @include transition(
      all 0.2s linear
    );
    
    //@include vertical-gradient(lighten($red-dark, 12%), $red-dark, $red-dark);
    
    p { margin: 0; }
    
    @media only screen and (max-width: 767px) {
      display: none;
    }
    &:hover {
      background: #eee;
    }
    
  }
  
  // Filters Button
  #block-block-6 {
  	//top: -12px;
  }
  
  // Close Button
  #block-block-5 {
  	display: none;
  } 
  
  // Facet Blocks
  .block-facetapi {
  	margin-bottom: 20px;
  }
  
  // Filter by Geography
  #block-facetapi-6rl94kyr8rhfidmzzq9crph5gdjmqmdr {
    margin-bottom: 20px;
  }
  
  // Filter by Theme
  #block-facetapi-hg3wmtxbqdlmem5j1trqwokhhgmxy1xw {
    margin-bottom: 20px;
  } 
  
  // Filter by Flag Size
  #block-facetapi-wy1pdkh0ucv6jukv4qohjnaz5nc8z1tp {
    margin-bottom: 20px;
  } 
  
  // Menus

  ul {
    margin: 0;
    padding: 0;
    
    &.facetapi-processed {
      margin: 0 0 0 10px;
    }
    
    li {
      margin: 0 0 2px;
      padding: 0;
      list-style: none;
    
      &.first {
        margin-top: 2px;
      }
      
      &.collapsed {
        a {
          &:after {
            position: absolute;
            right: 10px;
            font-family: fontello;
            content: "R";
            font-size: 1.1em;
          }
          &:hover {
            &:after {
              //content: "D";
              //@include rotate(90deg);
            }
          }
        }
      }
      
      &.active-trail {
        
      }
      
      &.expanded { 
        > a {
          &:after {
            display: none;
          }
          &:after {
            position: absolute;
            right: 10px;
            font-family: fontello;
            content: "D";
            font-size: 1.1em;
          }
        }
        a {
          left: -15px;
        }
      }
      
      // Hackish Theme Filter Titles
      &.expanded {
        position: relative;
        a {
        }
        li.expanded {
          a:after {
            right: 20px;
          }
          li.expanded {
            a:after {
              right: 30px;
            }
          }
        }
      }
    
      ul { padding: 0; }
    
      li {
        padding: 0 0 0 15px;
      }
    
    }
    
    a {
      position: relative;
      padding: 4px 30px 4px 10px;
      display: block;
      //background: #ddd;
      //background: rgba(white,0.2);
      //@include border-radius(3px);
      //border: 1px solid #bbb;
      //@include box-shadow(inset 0 -3px 10px rgba(black, 0.1));
      @include transition(
        all 0.2s ease-in-out
      );
      
      &.facetapi-active {
        display: inline;
        padding: 0;
        left: 0 !important;
        
        &:hover {
          background: none;
        }
        
      }
      
      &.active {
        border-left: 4px solid $red-dark;
        //background: #eee;
        background: rgba(white,0.4);
      }
      
      &:after, &:before {
        @include transition(
          //all 0.2s ease-in-out 0.2s
        );
      }
      
      &:hover {
        text-decoration: none;
        //background: #eee;
        background: rgba(white,0.2);
        
        @media only screen and (max-width: 767px) {
          background: white;
          background: rgba(white, 0.7);
        }
        
      }     
    }
  }
  
  a.facetapi-limit-link {
    text-align: center;
    display: block;
    width: 90%;
    margin: 10px auto 0;
    background: rgba(white, 0.2);
    padding: 5px;
    &:hover {
      text-decoration: none;
      background: rgba(white, 0.4);
      color: lighten($orange, 10%);
    }
  }
  
}

// 
// Sidebar
//

#sidebar-bg {
  position: absolute;
 	background: $grey-light;
  bottom: -20px;
 	top: -20px;
 	left: 0;
 	z-index: 3;
 	border-right: 1px solid #aaa;
 	@include box-shadow(0 0 10px rgba(black,0.5));
 	@media only screen and (max-width: 767px) {
 	  display: none;
 	}
}

body.page-taxonomy {
  #sidebar-bg {
    @include box-shadow(none);
  }
}

body.front {
  #sidebar-bg {
    display: none;
  }
}

body.filter_region #sidebar {
  @media only screen and (max-width: 767px) {
    margin-top: 86px;
  }
}

// Search Found (Applied Filters
#block-current-search-standard {
  padding: 0 10px;
}
  
#sidebar {
  position: relative;
  z-index: 3;
  
  @include transition(
    all 0.2s ease-in-out
  );
  
  // Mobile
  @media only screen and (max-width: 767px) {
    padding: 10px 0; 
    position: absolute;   
    left: -240px;
    margin: 43px 0 0;
    background: #eee;
    height: 10000px;
    @include box-shadow(inset 0 -7px 7px rgba(black,0.2));
    
    h2 {
      color: $red-dark !important;
    }
    
  }
  
  &.on-top {
    @media only screen and (max-width: 767px) {
      left: 0;
    }
  }

  h2 {
    font-size: 2em;
    color: white;
    margin: 0 10px;
    display: none;
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }
  
  a {
   color: $red-dark;
    @media only screen and (max-width: 767px) {
      background: $orange;
    }
  }
  
  ul.menu {
    margin: 0;
    padding: 0;
    
    li {
      margin: 0 1px 2px 0;
      padding: 0;
      list-style: none;
      
      @media only screen and (max-width: 767px) {
        margin: 0 0 2px 0;
      }
    
      &.first {
        margin-top: 2px;
      }
      
      &.collapsed {
        a {
          &:after {
            position: absolute;
            right: 10px;
            font-family: fontello;
            content: "R";
            font-size: 1.1em;
          }
          &:hover {
            &:after {
              //content: "D";
              //@include rotate(90deg);
            }
          }
        }
      }
      
      &.active-trail {
        
      }
      
      &.expanded { 
        > a {
          &:after {
            position: absolute;
            right: 10px;
            font-family: fontello;
            content: "D";
            font-size: 1.1em;
          }
        }
      }
    
      ul { padding: 0; }
    
      li {
        padding: 0 0 0 15px;
      }
    
    }
    
    a {
      position: relative;
      padding: 4px 10px;
      display: block;
      background: #ddd;
      background: rgba(255,255,255,0.4);
      //@include border-radius(3px);
      //border: 1px solid #bbb;
      //@include box-shadow(inset 0 -3px 10px rgba(black, 0.1));
      @include transition(
        all 0.2s ease-in-out
      );
      
      &.active {
        border-left: 4px solid $red-dark;
        background: #eee;
        background: rgba(255,255,255,0.7);
      }
      
      &:after, &:before {
        @include transition(
          all 0.2s ease-in-out 0.2s
        );
      }
      
      &:hover {
        text-decoration: none;
        background: #eee;
        background: rgba(255,255,255,0.7);
      }   
      
      @media only screen and (max-width: 767px) {
        background: none;
        color: $orange;
        
        &.active {
          border-left: 4px solid $red-dark;
          background: #eee;
          background: rgba(255,255,255,0.7);
        }
        
        &:after, &:before {
          @include transition(
            all 0.2s ease-in-out 0.2s
          );
        }
        
        &:hover, &.active {
          text-decoration: none;
          border: none;
          background: white;
          background: rgba(white,0.7);
        }          
        
      }  
      
    }
  }

}

// Content

#content {

  .block-facetapi {
    h2 {
      @include adjust-font-size-to($fs-l);
    }
    .item-list ul {
      li {
        display: inline-block;
        margin-left: 15px;
      }
    }
  }
}

// Mobile Select Menu (Replaces Menu in Sidebar)
#navigation-dropdown {
  display: none;
  @media only screen and (max-width: 767px) {
    display: none;
    width: 100%;
    margin: 0;
  }
}

//
// Footer
//

#block-menu-block-3 { // Footer Menu Block
  background-color: #1e5799; // Old browsers
  @include filter-gradient($red-dark, $red, horizontal); // IE6-9
  @include background-image(linear-gradient(left,  $red-dark 0%,$red 50%,$red-dark 100%));
  padding: 10px 0;
  color: white;
  text-transform: uppercase;
  ul {
    li {
      padding: 0;
      margin: 0;
      &:after {
        content: " | ";
      }
      &.last:after {
        content: none;
      }
      a {
        color: white;
      }
    }
  }
}

#block-block-2 { // Copyright & SSM
  background-color: $orange; // Old browsers
  color: $blue-dark;
  @include filter-gradient($orange, $orange, vertical); // IE6-9
  @include background-image(none);    
  a {
    color: $blue-dark;
  }
    
  text-align: center;
  //font-family: $secondary-typeface;
  @include secondary-typeface();
  padding: 10px 0;
  @include adjust-font-size-to($fs-m);
  #copyright {
    margin-bottom: 10px;
  }
  #ssm {
    margin-bottom: 0;
    @include adjust-font-size-to($fs-s);
    a {
      color: $blue-dark;
      @media only screen and (max-width: 767px) {
        color: white;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    color: white;
    background-color: #00213d; // Old browsers
    @include filter-gradient(#023b72, #00213d, vertical); // IE6-9
    @include background-image(linear-gradient(top, #023b72 0%,#00213d 100%));  
  }
}

//
// Seals
//
#block-views-seals-block{
  text-align: center;
  
  .seal{
    display: inline-block;
    margin: 0 15px;
    height: 80px;
  }

  .seal img {
    margin: 0;
  }
  
  @media only screen and (max-width: 767px) {
    display: none;
  }

}


//
// Page Bottom
//

#block-menu-block-4 { // Main Menu Block below footer

  //font-family: $secondary-typeface;
  @include secondary-typeface();
  background: $orange;
  width: 100%;
  padding: 0 10px 10px;
  display: none;

  ul {
    padding: 0;
    list-style: none;
    li { 
      list-style: none;
      padding: 0;
      margin: 0 0 5px;
      a {
        background: $red-dark;
        display: block;
        color: white;
        font-size: $fs-xxl;
        padding: 10px 0 5px 25px;
        &:hover {
          text-decoration: none;
          background: lighten($red-dark, 15%);
        }
      }
    }
  }
}

// Contact Info Block
#block-block-12 { // Header Contact Info
  text-align: center;
  background: $orange;
  width: 100%;
  padding: 8px 0 20px;
  color: $red-dark;
  @include adjust-font-size-to($fs-m);
  display: none;
  z-index: 2;
  @media only screen and (max-width: 767px) {
    display: block;
  }
  ul {
    list-style: none;
    //font-family: $secondary-typeface;
    @include secondary-typeface();
    margin: 0;
    padding: 0;
    display: inline;
    @media only screen and (max-width: 767px) {
      display: block;
    }
    li {
      display: inline;
      &:after {
        content: " | ";
      }
      &.last {
        &:after {
          content: none;
        }
      }
      a {
        color: $blue-dark;
        @media only screen and (max-width: 767px) {
          color: $red-dark;
        }
      }
    }
    &.first {
      li.last:after {
        content: " | ";
        @media only screen and (max-width: 767px) {
          content: none;
        }
      }
    }
  }
}



// Blog Nodes //

.node-type-blog-post,
.view-blog-with-blocks {
  .field-name-field-blog-photo {
    clear: right;
     img {
       float: left;
       padding: 20px;
      }
    }
 /*
 .field-name-submitted-by {
    margin: 10px 0;
  }
*/

  .field-name-body {
    clear: left;
  }
}












